<template>
  <div>
    <!-- Main Banner -->
    <MainBanner />
    <!-- end Main Banner -->
    <div class="container py-8 mx-auto text-center">
      <h1 class="pb-6 text-3xl font-bold text-green-1 md:text-4xl">
        Message Sending Successful!
      </h1>
      <p class="px-4 text-lg md:px-0 opensan400">
        Thank you for your kind interest in Aitsulab Enterprise. We will get back to you
        as soon as possible.
      </p>
      <div class="mt-6">
        <router-link to="/" class="block w-48 mx-auto">
          <p class="block py-2 text-white bg-black rounded-lg">
            Back To Home
          </p>
        </router-link>
      </div>
      <div></div>
    </div>
    <!-- copyRight -->
  </div>
</template>

<script>
import MainBanner from "@/components/MainBanner.vue";

export default {
  components: {
    MainBanner,
  },
};
</script>

<style></style>
